<template lang="pug">
  include ../mixins/_mixins.pug
  section.c-about.c-mobile__center
    template(v-if="$root.choseTemplateView")
      .container.c-mobile__center
        .navbar
          .navbar__item.navbar__item-left
            button.navbar__item-btn(v-if="$root.choseTemplateView && $root.choseTemplateView != 'form'", @click="hideTemplate()")
              <svg-icon name="arrow"/>
          h1.c-title {{title}}
          .navbar__item.navbar__item-right
            button.navbar__item-btn(v-if="$root.choseTemplateView === 'form'", @click="$root.choseTemplateView = 'contacts'")
              <svg-icon name="close"/>
        template(v-if="$root.choseTemplateView === 'form'")
          .c-about__form.c-mobile__block
              <Form/>
        template(v-if="$root.choseTemplateView === 'about'")
          .c-about__top
            .c-guide__text(v-html="text_about")
            .c-guide__nav
              ul.c-about__certificat-list
                li.c-about__certificat-item(@click='$modal.show("delivery")') Доставка и оплата
                li.c-about__certificat-item(@click='$modal.show("requisites")') Реквизиты
                li.c-about__certificat-item(@click='$modal.show("userAgreement")') Пользовательское соглашение
        template(v-if="$root.choseTemplateView === 'serti'")
          .c-about__certificat-detail
            .c-about__certificat-pic(v-viewer="options")
                <img :data-src="require(`@/assets/img/content/certificate/${certificat_pic}`)"  :src="require(`@/assets/img/content/certificate/preview/${certificat_pic}`)" :alt="certificat_title">
            .c-about__certificat-body
              .c-about__certificat-top
                .c-about__certificat-title {{certificat_title}}
                template(v-if="$root.window.width > 767")
                  .c-about__certificat-text {{certificat_text}}
              .c-about__certificat-bottom
                ul.c-about__certificat-list
                  li.c-about__certificat-item(v-for="(item, index) in certificats" @click="changecertificat(item.name, index)" :class="{ 'is-active': certificat_index == index }") {{item.name}}
          template(v-if="$root.window.width < 767")
            .c-about__certificat-text {{certificat_text}}
        template(v-if="$root.choseTemplateView === 'contacts'")
          ul.c-about__contacts
            li.c-about__contacts-item.c-about__contacts-item--phone
              a.c-about__contacts-link(:href="'tel:'+$store.state.phoneLink")
                <svg-icon name="tel"/>
                span.c-about__contacts-name {{$store.state.phone}}
            li.c-about__contacts-item.c-about__contacts-item--mail
              a.c-about__contacts-link(:href="'mailto:'+$store.state.email")
                <svg-icon name="mail"/>
                span.c-about__contacts-name {{$store.state.email}}
            //-li.c-about__contacts-item
              a.c-about__contacts-link(:href="$store.state.instargam" target="_blank")
                <svg-icon name="instargam"/>
                span.c-about__contacts-name Instargam
            //-li.c-about__contacts-item
              a.c-about__contacts-link(href="")
                <svg-icon name="wa"/>
                span.c-about__contacts-name WhatsApp
            //-li.c-about__contacts-item
              a.c-about__contacts-link(href="")
                <svg-icon name="vb"/>
                span.c-about__contacts-name Viber
            //-li.c-about__contacts-item
              a.c-about__contacts-link(href="")
                <svg-icon name="tg"/>
                span.c-about__contacts-name Telegram
          .c-about__info(v-html="text_info")
          template(v-if="$root.window.width < 767")
            .c-text-center
              button.c-btn.c-btn__about(@click="choseTemplate('form')", title='СВЯЖИТЕСЬ С НАМИ') СВЯЖИТЕСЬ С НАМИ
          template(v-else)
            .c-about__form
              <Form/>
    template(v-else-if="$root.window.width < 1199")
      .c-about__nav
        .navbar
          h1.c-title ONLY DIAMOND
      .c-about__chose.c-mobile__block
        .c-about__chose-item(@click="choseTemplate('about'), title='О КОМПАНИИ'")
          .c-about__chose-icon
            <svg-icon name="about" class="icon__color-accent"/>
          .c-about__chose-name О КОМПАНИИ
        .c-about__chose-item(@click="choseTemplate('serti'), title='О СЕРТИФИКАТЫ'")
          .c-about__chose-icon
            <svg-icon name="serti" class="icon__color-accent"/>
          .c-about__chose-name СЕРТИФИКАТЫ
        .c-about__chose-item(@click="choseTemplate('contacts'), title='КОНТАКТЫ'")
          .c-about__chose-icon
            <svg-icon name="contacts" class="icon__color-accent"/>
          .c-about__chose-name КОНТАКТЫ
    template(v-else)
      .container
        .c-about__logo
          +icon('OnlyDiamondLogo2')
        .c-about__inner
          .c-about__left
            .c-about__top
              .c-guide__title О КОМПАНИИ
              .c-guide__text(v-html="text_about")
              .c-guide__nav
                ul.c-about__certificat-list
                  li.c-about__certificat-item(@click='$modal.show("delivery")') Доставка и оплата
                  li.c-about__certificat-item(@click='$modal.show("requisites")') Реквизиты
                  li.c-about__certificat-item(@click='$modal.show("userAgreement")') Пользовательское соглашение
            .c-about__certificat
              .c-guide__title СЕРТИФИКАТЫ
              .c-about__certificat-detail
                .c-about__certificat-pic(v-viewer="options")
                  <img :data-src="require(`@/assets/img/content/certificate/${certificat_pic}`)"  :src="require(`@/assets/img/content/certificate/preview/${certificat_pic}`)" :alt="certificat_title">
                .c-about__certificat-body
                  .c-about__certificat-top
                    .c-about__certificat-title {{certificat_title}}
                    .c-about__certificat-text {{certificat_text}}
                  .c-about__certificat-bottom
                    ul.c-about__certificat-list
                      li.c-about__certificat-item(v-for="(item, index) in certificats" @click="changecertificat(item.name, index)" :class="{ 'is-active': certificat_index == index }") {{item.name}}
          .c-about__right
            .c-guide__title КОНТАКТЫ
            ul.c-about__contacts
              li.c-about__contacts-item.c-about__contacts-item--phone
                a.c-about__contacts-link(:href="'tel:'+$store.state.phoneLink")
                  <svg-icon name="tel"/>
                  span.c-about__contacts-name {{$store.state.phone}}
              li.c-about__contacts-item.c-about__contacts-item--mail
                a.c-about__contacts-link(:href="'mailto:'+$store.state.email")
                  <svg-icon name="mail"/>
                  span.c-about__contacts-name {{$store.state.email}}
              //-li.c-about__contacts-item
                a.c-about__contacts-link(:href="$store.state.instargam" target="_blank")
                  <svg-icon name="instargam"/>
              //-li.c-about__contacts-item
                a.c-about__contacts-link(href="")
                  <svg-icon name="wa"/>
                  span.c-about__contacts-name WhatsApp
              //-li.c-about__contacts-item
                a.c-about__contacts-link(href="")
                  <svg-icon name="vb"/>
                  span.c-about__contacts-name Viber
              //-li.c-about__contacts-item
                a.c-about__contacts-link(href="")
                  <svg-icon name="tg"/>
                  span.c-about__contacts-name Telegram
            .c-about__info(v-html="text_info")
            .c-about__form
              <Form title="Свяжитесь с нами"/>
</template>

<script>
import certificats from '@/data/certificat.json'
import Form from '@/components/Form'

export default {
  name: 'About',
  components: {
    Form,
  },
  metaInfo: {
    title: 'О компании | OnlyDiamond',
    meta: [{ name: 'description', content: 'Информация о компании OnlyDiamond' }],
  },
  data() {
    return {
      text_about:
        '<p>У нас представлены все новинки в мире ювелирных изделий, а консультанты с радостью помогут Вам выбрать особенное украшение. Вся продукция имеет необходимые сертификаты.</p><p>Мы осуществляем продажу, как готовых бриллиантов, так и предоставляем возможность сделать украшение на заказ.</p>',
      text_info:
        'Мы продаём бриллианты не только в розницу, но и оптом.<br> Для уточнения подробностей свяжитесь с нами по телефону или через форму ниже.',
      options: {
        url: 'data-src',
        navbar: false,
        toolbar: false,
        movable: false,
        title: false,
        minZoomRatio: 0.1,
      },
      title: '',
      certificats: certificats,
      certificat_index: 0,
      certificat_pic: certificats[0].img,
      certificat_title: certificats[0].title,
      certificat_text: certificats[0].text,
    }
  },
  methods: {
    changecertificat(item, index) {
      this.certificat_index = index
      var result = this.certificats.filter(x => {
        return x.name == item
      })
      if (result.length) {
        this.certificat_pic = result[0].img
        this.certificat_title = result[0].title
        this.certificat_text = result[0].text
      }
    },
    choseTemplate(id) {
      this.$root.choseTemplateView = id
    },
    hideTemplate() {
      this.$root.choseTemplateView = ''
    },
  },
}
</script>
